/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css";
@import '../node_modules/tailwindcss/base.css';
@import '../node_modules/tailwindcss/components.css';
@import '../node_modules/tailwindcss/utilities.css';
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

@import '../node_modules/tippy.js/dist/tippy.css';
@import '../node_modules/tippy.js/animations/scale.css';

/* You can add global styles to this file, and also import other style files */
@import "~ngx-smart-modal/ngx-smart-modal";

/* You can add global styles to this file, and also import other style files */

html,
body {
  box-sizing: border-box !important;
  max-height: 100%;
  overflow-y: hidden;
  font-family: 'Manrope', sans-serif;
}

.reading-block {
  font-size: 24px !important;
}

.swal2-title {
  font-size: 24px !important;
}

.fc-event-title {
  white-space: pre-wrap;
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-event-time {
  font-size: var(--fc-small-font-size, .85em);
}

.fc-timegrid-event,
.fc-daygrid-event {
  padding: 0 10px !important;
}

.fc-daygrid-event {
  display: grid !important;
}

.fc-event-time {
  display: none;
}

.event-footer {
  padding: 0px !important;
  justify-content: space-evenly !important;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 24px !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 24px !important;
}

ol-list {
  list-style-type: none !important;
  padding-left: 0px !important;
  margin: 0px !important;
}

ul-list,
menu-list {
  list-style-type: disc !important;
  padding-left: 0px !important;
  margin: 0px !important;
}



@media only screen and (max-width:767px) {
  .mob_100_cus {
    width: 100%;
  }

  .fc-toolbar-chunk {
    text-align: center;
  }

  .fc .fc-toolbar {
    display: table !important;
  }

  .grid-cols-12.calendar-div {
    /* grid-template-columns : none;
    overflow: scroll; */
    display: flex;
    flex-wrap: wrap;
  }

  .shift-button:after,
  .shift-button:before {
    display: none !important;
  }

  .grid-cols-12.calendar-div .col-start-1,
  .grid-cols-12.calendar-div .col-start-3 {
    width: 100%;
  }

  .sms-button {
    margin-top: 10px;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 200px;
}


.fc-daygrid-dot-event {
  background: #3788d8 !important;
  font-size: var(--fc-small-font-size, .85em) !important;
}

/* button.fc-event {
  max-width: 230px;
} */

.object-contain {
  object-fit: contain;
}

.ck-editor__editable {
  min-height: 170px !important;
}

.bg-indigo-500 {
  background-color: rgb(99 102 241);
}

.bg-blue-500 {
  background-color: rgb(29 78 216);
}

.bg-gray-200 {
  background-color: rgb(229 231 235);
}

/* .sms-button {
  padding-top: 7px !important;
  padding-bottom: 5px !important;
} */

.fc-list-event.fc-event,
.fc-list-event.fc-event:hover {
  background: #3788d8 !important;
}

/*.cdk-overlay-pane.owl-dt-popup {
  top: 60px !important;
}*/
.auditlogs-filter-section .ng-value {
  white-space: unset !important;
}

@media only screen and (max-width:865px) {
  .sms-button {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }

  .call-button {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1280px) {
  .xl\:px-24 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}


/**** GLOBAL PAGES  ***/
.__page-container {
  font-family: 'Manrope', sans-serif;
}

.__page-container button {
  font-family: 'Manrope', sans-serif;
}

.__section-header {
  /* border-bottom: 1px solid #D4DDE3; */
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.__section-header .__page-title {
  font-size: 32px;
  font-weight: 800;
  margin: 0px;
  color: #1c252c;
  letter-spacing: -0.015em;
  line-height: 1.4;
}

/** ng-select input **/
.ng-select-container {
  background: transparent !important;
  border: none !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.__ng-select-autocomplete {
  min-width: 160px;
  width: 100%;
  max-width: 100%;
  outline: none;
  appearance: none;
  border: 1px solid #a6c8e7;
  border-radius: 8px;
  min-height: 40px;
  width: 100%;
  position: relative;
  color: #1c252c;
  font-style: italic;
  background: #fff;
  font-size: 16px;
}

.__ng-select-element-value {
  background: #a6c8e770;
  font-size: 12px;
  padding: 3px 7px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.ng-select-container .ng-value.ng-star-inserted {
  background:transparent !important;
}
.__ng-select-element-value svg {
  border-radius: 100%;
}
.__ng-select-element-value * {
  color: #001B66 !important;
  /* font-weight: bold !important; */
  font-size: 14px !important;
}
/** selec input **/
.__select {
  border: 1px solid #a6c8e7;
  border-radius: 8px;
  height: 40px;
  outline-color: transparent;
  color: #1c252c;
  font-size: 16px;
  font-style: italic;
  /* width: 100px; */
  min-width: 150px;
}
.__input {
  all: unset;
  box-sizing: border-box;
  padding-top: 0.5rem;
  padding-right: 60px;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  outline: unset;
  width: 100%;
  border: 1px solid #a6c8e7;
  border-radius: 8px;
  height: 40px;
  background: transparent;
  outline-color: transparent;
  color: #1c252c;
  font-size: 16px;
  font-style: italic;
  min-width: 150px;
}

.__filter-form {
  display: flex;
  align-items: flex-start;
}

.__filter-form .__filter-form_input-container {
  margin: 5px;
}


.__btn-main {
  color: #fff;
  background-color: #2046cf;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 8px 20px;
  text-align: center;
  min-width: 100px;
  border-radius: 500px;
  outline: 0;
  font-weight: 600;
  cursor: pointer;
}
.--danger {
  background-color: rgb(218, 72, 72);
}
.__btn-main:disabled {
  opacity: .8;
}
.__btn-main:disabled svg{
  display: none;
}

.__page-container-fixed {
  position: fixed;
  right:0px;
  top:0px;
  width: 40%;
  min-width: 600px;
  height: calc(100% - 0px);
  max-height: calc(100% - 0px);
  background:#fff;
  z-index: 101;
}

.bg-wwhite {
  position: fixed;
  left:0px;
  top:0px;
  background:rgba(255,255,255,.8);
  width: 100%;
  height: 100%;
  z-index: 100;
}


/**** update scroll bar for the whole app ***/
/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #76bbfb;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d2e9ff;
}



/**** ngx-gauge label ***/
.reading-label {
  transform: translate(-50%, 490%) !important;
}



/*** status ****/

.__status {
  font-size: 13px;
  font-weight: bold;
  border-radius: 4px;
  padding: 4px 5px;
  /* background-image: url(/assets/icons/table-status-bg.svg); */
  white-space: nowrap;
}

.__status.pending {
  color: #1c252c;
  background-color: #d4dde3;
}

.__status.acknowledged {
  color: #09526c;
  background-color: #afe5f8;
}

.__status.paused {
  color: #af2c2c;
  background-color: #fbdada;
}

.__status.signedoff {
  color: #14713f;
  background-color: #adf0cc;
}

.__status.cancelled {
  color: #BF360C;
  background-color: #FBE9E7;
}

.__status.duplicated {
  color: #2146cf;
  background-color: #2146cf1a;
}


/**** JS PDF ***/
/* .timesheet-pdf {
  transform: scale(.3);
} */


._template-pdf {
  width: 100%;
  max-width: 100%;
  padding:10px;
}


._template-pdf * {
  font-family: Verdana, Arial, sans-serif;
}

._tempalte-pdf-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 426px; /* full-width = 446 */
  line-height: 90%;
  /* background:red; */
}

._template-pdf-details {
  width: 426px; /* full-width = 446 */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #E3F2FD;
  padding:10px;
  border-radius: 10px;
  margin-top: 15px;
}

._template-pdf-details ._template-pdf-details-title {
  font-weight: bold;
  color: #01579B;
  font-size: 10px;
  margin-top: -10px;
}


._template-pdf-table {
  width: 426px; /* full-width = 446 */
  font-size: 8px;
  border-collapse: collapse;
  margin-top: 15px;
}

._template-pdf-table  th {
  background-color: #fafafa;
}

._template-pdf-table-col {
  border: 1px solid #ccc;
  border-collapse: collapse;
  padding:10px;
  padding-top: 0px;
}

._template-pdf-footer {

  width: 426px; /* full-width = 446 */
  margin-top: 15px;

}

._template-pdf-footer-signature {
  border:1px solid #ccc;
  background:#fff;
  border-radius: 5px;
  margin-top: 15px;

}


.bg-rose-danger {
 background-color: #FBE9E7
}

.text-danger {
 color: #BF360C
}

.badge-important {
  color: #af2c2c;
  background-color: #fff5f5;
  padding: 4px 8px;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  border-radius: 0.375rem;
  box-shadow: inset 0 0 0 0px #fff, inset 0 0 0 calc(1px + 0px) rgb(126 34 206 / 0.1),  0 0 #0000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


/* tippy tooltip theme */
.tippy-box[data-theme~='primary'] {
  background-color: #f1f5fe;
  color: #001b66;
}
/* CSS arrow  */
.tippy-box[data-theme~='primary'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #f1f5fe;
}
.tippy-box[data-theme~='primary'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #f1f5fe;
}
.tippy-box[data-theme~='primary'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: #f1f5fe;
}
.tippy-box[data-theme~='primary'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: #f1f5fe;
}


/* buttone */

.spinner {
  display: inline-block;
  width: 16px; /* Size of the spinner */
  height: 16px; /* Size of the spinner */
  border: 2px solid rgba(255, 255, 255, 0.3); /* Light spinner color */
  border-top-color: #fff; /* Top border color */
  border-radius: 50%; /* Make it a circle */
  animation: spin 1s linear infinite; /* Spinning animation */
  margin-right: 5px; /* Space between spinner and button text */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
